// Styles file for `homewindowsurvey.com`.

// Styles written here will override the corresponding component
// This is a stylesheet example of `Harpquiz`.

import { css, keyframes } from 'styled-components';
import images from './images';
// href="https://fonts.googleapis.com/css2?family=News+Cycle&display=swap"
// <link href="https://fonts.googleapis.com/css2?family=News+Cycle&display=swap" rel="stylesheet">
// Theming
const DEFAULT_COLORS = {
  questionTitle: '#333',
  text: '#000',
  headline: '#000',
  link: '#39577b',
  choiceInput: '#f3f3f3',
  choiceInputHover: '#e6e6e6',
  submitButtonBackground:
    'linear-gradient(103deg, rgb(15, 94, 105) 4% 4%, rgb(43, 142, 113) 96% 96%)',
  submitButtonText: '#fff',
  headerBackground: '',
  mainBackground: '#fff',
  surveyBackground: '#fff',
  footerBackground: '#fff',
  progressBarBackground: '#f5f5f5',
  progressBarFill: '#a4bb63',
  sliderFill: '#f5f5f5',
};

const DEFAULT_FONT = {
  questionTitle: '24px',
  text: '16px',
  headline: '28px',
  submitButton: '20px',
  tcpa: '11px',
  footerText: '12px',
};

const DEFAULT_SIZE = {
  headerHeight: '150px',
  headerMobileHeight: '',
  submitButtonWidth: '180px',
  inputFieldHeight: '60px',
  inputFieldWidth: '100%',
  inputFieldMobileWidth: '',
};

// Header
const HeaderContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid;
  height: ${DEFAULT_SIZE.headerHeight}; /* Don't forget to change the min-height calculation for SurveyBackground */
  max-width: 100%;
  width: 100vw;
  padding: 15px 20px;
  margin: auto;
  background: ${DEFAULT_COLORS.headerBackground};

  &.tyHeader,
  &.tyHeader-1 {
    justify-content: flex-start;
    padding: 10px 0 10px 20px;
    max-width: 100%;
    height: 150px;
    @media (max-width: 801px) {
      display: block;
    }
  }

  @media (max-width: 801px) {
    height: 90px;
    padding: 10px;
  }
`;

const SvgLine = css`
  width: 470px;
`;

const HeaderImage = css`
  height: auto;
  width: 100%;
  max-width: 350px;
  max-height: 155px;
  margin-bottom: 0;

  .tyHeader &,
  .tyHeader-1 & {
    max-width: 92px;
    @media (max-width: 801px) {
      max-height: 57px;
      max-width: 45px;
    }
  }

  @media (max-width: 801px) {
    height: 100%;
  }
`;

// Only used when sites background color is needed
// specificy "background-color" because of the blurred-rates image.
const AppWrapper = css`
  background-color: ${DEFAULT_COLORS.mainBackground} !important;
  position: relative;

  &#typWrapper {
    #interLincxTarget {
      margin-top: 60px;
    }
    #adButlerShowAll {
      margin-top: 45px;
    }
  }

  #tyPageHeadlineContainer,
  #tyPageHeadlineContainer-1 {
    background: transparent;
    position: absolute;
    top: 0;
    z-index: 9999;

    h1 {
      color: #e9973e;
      text-shadow: 2px 2px #333;
    }

    h3 {
      color: #fff;
      text-shadow: 2px 2px #333;
      @media (max-width: 801px) {
        margin-bottom: 10px;
      }
    }

    p {
      color: #fff;
      text-shadow: 2px 2px #333;
    }
    @media (max-width: 801px) {
      top: 18px;
    }
  }

  #CredibleDisclosure {
    background: #fff;
  }
`;

// Main Background
const SurveyBackground = css`
  min-height: calc(100vh - 92px);
  max-width: 960px;
  width: 100vw;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 801px) {
    background-color: ${DEFAULT_COLORS.surveyBackground};
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: transparent;
  }
`;

const SurveyContainer = css`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  max-width: 600px;
  padding: 35px 20px 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px #939699;
  margin: 30px auto 0;

  div {
    &#alternate-container {
      /* Used if you need to overwrite the width of the hidden container */
      /* width: 100%; */
    }
  }
`;

const QuestionNumber = css`
  font-size: 14px;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  margin-bottom: 5px;
`;

const FormContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0;
  width: 100%;
  padding: 10px 0;

  @media (max-width: 801px) {
    padding-top: 30px;
    .surveyContainerFF & {
      padding-top: 0;
    }
  }
`;

const GlobalSealImg = css`
  display: none;
  margin: 20px;
  max-width: 110px;
`;

// Containers
// Multiple choice
const ChoiceContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 8px 0;
  transition: background-color 230ms ease;
  background: #fff;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  height: ${DEFAULT_SIZE.inputFieldHeight};
  color: ${DEFAULT_COLORS.text};
  cursor: pointer;
  font-size: 18px;
  border: ${props =>
    props.border
      ? '1px solid red'
      : '1px solid #ccc'} !important; /* overrides initial setting*/
  border-radius: 3px;

  .input-opt_served_military & {
    @media (max-width: 801px) {
      font-size: 16px;
    }
  }

  // background: #fff url(${images.Unchecked}) 60px 50%/20px no-repeat;
  @media (max-width: 801px) {
    background: #fff url(${images.Unchecked}) 20px 50%/20px no-repeat;
  }

  &:active {
    opacity: 0.9;
    background: ${DEFAULT_COLORS.submitButtonBackground};
  }

  &:hover {
    opacity: 0.9;
    background: ${DEFAULT_COLORS.submitButtonBackground};
    @media (max-width: 801px) {
      opacity: 1;
      background: #fff url(${images.Unchecked}) 20px 50%/20px no-repeat;
    }
  }
`;

// Styles for selected radio button (multiple-choice)
const InputContainer = css`
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  justify-content: center;

  > input:checked + label {
    background: ${DEFAULT_COLORS.submitButtonBackground};
    justify-content: center;
    @media (max-width: 801px) {
    background: ${DEFAULT_COLORS.submitButtonBackground};
  }
  }

  &.input-WindowType {
    box-sizing: border-box;
    width: 31%;
    height: 150px;
    color: transparent;
    margin 1% 5px;
    padding: 0;
    transition: all .3s;
    label {
      box-sizing: border-box;
      color: #333;
      width: 100%;
      height: 146px;
      padding: 0;
      display: block;
      text-align: center;
      padding-top: 110px;
      font-weight: 600;
      margin: 0;
      background: transparent;
      border: 2px solid #7fcdff !important;
      transition: all .3s;
      font-size: 16px;
      .labelHelper {
        display: block;
        font-size: 9px;
      }
      :hover{
        @media (min-width: 800px){
        border-radius: 50%;
        font-size: .8rem;
        padding-top: 95px;
        }
    }
    }
    > input:checked + label {
      @media (min-width: 800px){
        border-radius: 50%;
        font-size: .8rem;
        padding-top: 95px;
      }
    }
    :hover{
      @media (min-width: 800px){
        border-radius: 50%;
      }
    }
  }

  &.input-Windows {
    label {
      /* background: 0 0; */
      background: unset;
      padding: 0;
      justify-content: center;
    }

    > input:checked + label {      
        background: #6eb7c2;
        box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.25);
        color: #fff;      
    }
  }

  /* Double Hung styling */
  > input[id='Double Hung'] + label {
    background: #fff url(${images.DoubleHung}) 50% 10px / 75% no-repeat;
    @media (min-width: 800px){
      &:hover {
        background: #eeefee url(${images.DoubleHung}) 50% 10px / 60% no-repeat;
      }
    }
  }
  > input[id='Double Hung']:checked + label {
    background-size: 60%;
    background-color: #eeefee;
  }
  /* Checked Styling */
  > input[id='Double Hung']:checked + label, 
  > input[id='Fixed Picture']:checked + label, 
  > input[id='Sliding']:checked + label, 
  > input[id='Casement']:checked + label, 
  > input[id='Arched or Elliptical']:checked + label, 
  > input[id='Skylight']:checked + label {
        background-color: #eeefee;
        background-size: 60%;
        border-radius: 50%;
  }

  /* Fixed styling */
  > input[id='Fixed Picture'] + label {
    background: #fff url(${images.Fixed}) 50% 10px / 75% no-repeat;
    @media (min-width: 800px){
      &:hover {
        background: #eeefee url(${images.Fixed}) 50% 10px / 60% no-repeat;
      }
    }
  }

  /* Sliding styling */
  > input[id='Sliding'] + label {
    background: #fff url(${images.Sliding}) 50% 10px / 75% no-repeat;
    @media (min-width: 800px){
      &:hover {
        background: #eeefee url(${images.Sliding}) 50% 10px / 60% no-repeat;
      }
    }
  }

  /* Casement styling */
  > input[id='Casement'] + label {
    background: #fff url(${images.Casement}) 50% 10px / 75% no-repeat;
    @media (min-width: 800px){
      &:hover {
        background: #eeefee url(${images.Casement}) 50% 10px / 60% no-repeat;
      }
    }
  }

  /* Arched styling */
  > input[id='Arched or Elliptical'] + label {
    background: #fff url(${images.Arched}) 50% 10px / 75% no-repeat;
    @media (min-width: 800px){
      &:hover {
        background: #eeefee url(${images.Arched}) 50% 10px / 60% no-repeat;
      }
    }
  }

  /* Skylight styling */
  > input[id='Skylight'] + label {
    background: #fff url(${images.Skylight}) 50% 10px / 75% no-repeat;
    @media (min-width: 800px){
    &:hover {
      background: #eeefee url(${images.Skylight}) 50% 10px / 60% no-repeat;
    }
    }
  }

  @media (max-width: 801px) {
    &.input-WindowType {
      width: 47%;
    }
  }
`;

// Input
const inputStyle = css`
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  height: ${DEFAULT_SIZE.inputFieldHeight};
  border-radius: 3px;
  font-size: 18px;
  background: #fff;
  text-align: center;
  border: ${props =>
    props.border
      ? '1px solid red'
      : '1px solid #6eb7c2'} !important; /* overrides initial setting*/
  ::-webkit-input-placeholder {
    color: #000;
    font-weight: 500;
    font-size: 18px;
  }
`;

const InputField = css`
  ${inputStyle}
  border: ${props => props.border};

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyledTextArea = css`
  ${inputStyle}
  border-radius: 5px;
  border: ${props => props.border};
`;

const AddressContainer = css`
  display: flex;
  flex-direction: column;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  width: 100%;

  p {
    margin-bottom: 0;
    margin-top: 10px;
    &.emailLabel {
      width: 100%;
    }
  }

  input {
    width: 100%;
    max-width: ${DEFAULT_SIZE.inputFieldWidth};
    margin: auto;
  }
`;

// Dropdown
const StyledSelect = css`
  ${inputStyle}
  -webkit-appearance: none;
  background: url(${images.Dropdown}) 98% 50% no-repeat #fff;
  background-size: 20px;
`;

// Zip input
const StyledZip = css`
  ${inputStyle}
  margin-bottom: 12px;
  color: #555;
  padding: 0 20px;
  height: 50px;
  border: ${props =>
    props.border
      ? '1px solid red'
      : '1px solid #6eb7c2'} !important; /* overrides initial setting*/

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const FinalInputContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  color: #3e3e3e;

  input,
  select {
    margin-top: 0;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    padding-left: 20px;
  }
`;

// Hidden inputs
const HiddenContainer = css`
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  width: 100%;
  margin-top: ${props => props.secondary && '0'};

  input {
    height: ${DEFAULT_SIZE.inputFieldHeight};
  }
`;

// Survey styles
const QuestionTitle = css`
  color: ${DEFAULT_COLORS.questionTitle};
  font-weight: 600;
  font-size: ${DEFAULT_FONT.questionTitle};
  line-height: 1;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  &.questiontitle-first_name {
    display: none;
  }

  @media (max-width: 801px) {
    font-size: 18px;
  }
`;

const InputTitle = css`
  margin-bottom: 0;
  text-align: center;
`;

const Subtitle = css`
  display: none;

  width: 100%;

  &.subtitle-first_name,
  &.subtitle-phone_primary,
  &.subtitle-credit_rating {
    display: block;
    text-align: center;
  }
`;

const TipContainer = css`
  text-align: center;
  background: #fff;
  padding: 10px;
  color: #000;
  font-size: 15px;
  border-radius: 3px;
  width: 80%;
`;

const QuestionContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const QuestionBodyContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;

  &.question-WindowType {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &.question-Windows {
    display: flex;
    flex-direction: row;
  }
  .emailLabel,
  .street-address-subtitle {
    display: none;
  }
`;

// Slider
const StyledSlider = css`
  width: 100%;
`;

const StyledThumb = css`
  background: url(${images.SliderArrows}) 50% 50%/14px no-repeat #333;
  border: 1px solid #bdc3c7;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transform: scale(1.1);
`;

const DisplayNumber = css`
  font-size: ${({ primary }) => (primary ? '18px' : '14px')};
  font-weight: 500;
  padding: 10px;
  margin-bottom: 0;
`;

const NumberContainer = css`
  display: flex;
  justify-content: space-between;
  width: inherit;
`;

const SliderContainer = css`
  width: inherit;
  height: 10px; /* Don't forget to change the height of "FillContainer" as well */
  border-radius: 8px;
  border: 1px solid #bdc3c7;
  background: #fff;
`;

const FillContainer = css`
  position: absolute;
  height: 10px;
  border-radius: 8px 0 0 8px;
  background: ${DEFAULT_COLORS.sliderFill};
  width: ${props => `${props.width}%`};
`;

// ProgressBar
const ProgressContainer = css`
  margin: 0 auto 10px auto;
  width: 100%;
  height: 10px;
  background: ${DEFAULT_COLORS.progressBarBackground};
  border-radius: 5px;
  overflow: hidden;
  .surveyContainerFF & {
    display: none;
  }
`;

const StatusBar = css`
  background-size: 50px 50px;
  width: ${props => props.width};
  height: inherit;
  background-color: ${DEFAULT_COLORS.progressBarFill};
  transition: width 0.25s ease;
`;

// Used for the footer progressbar.
const FooterNumberStatus = css`
  color: #afafaf;
  font-size: 14px;
  margin-bottom: 10px;
  .surveyContainerFF & {
    display: none;
  }
`;

// buttons
const SubmitButton = css`
  margin: 15px 0;
  padding: 7px 5px;
  background: linear-gradient(
    103deg,
    rgb(15, 94, 105) 4% 4%,
    rgb(43, 142, 113) 96% 96%
  );
  border: 0px;
  font-size: ${DEFAULT_FONT.submitButton};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${DEFAULT_COLORS.submitButtonText};
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  height: 52px;
  width: 220px;

  .initial & {
    max-width: 100%;
  }

  &:hover {
    opacity: 0.9;
    background: 2px solid oklch(81.49% 0.006 239.84);
  }

  /* Final Submit button is different than the 'next' buttons */
  &#submit-button {
    max-width: 90%;
    margin: auto;
    font-size: 25px;

    @media (max-width: 801px) {
      width: 200px;
      font-size: 19px;
      margin: 0;
    }
  }
`;

const BackButton = css`
  border: none;
  background: inherit;
  color: ${DEFAULT_COLORS.link};
  margin-bottom: 5px;
  cursor: pointer;
  display: none;

  &#previous-button {
    background: url(${images.BackArrow}) 100% / cover no-repeat;
    height: 56px;
    width: 56px;
    color: transparent; /** hides text in favor of the background image */
  }

  &:hover {
    opacity: 0.8;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background: none;
  }
`;

const ButtonContainer = css`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0;
  .BackButton {
    display: none;
  }
`;

// Footer
const FooterContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  background: ${DEFAULT_COLORS.footerBackground};
  max-width: 960px;
  width: 100%;
  margin: auto;

  p {
    margin-bottom: 0;
    padding: 10px;
  }

  a {
    color: ${DEFAULT_COLORS.link};
  }

  @media (max-width: 801px) {
    background: #00486b;
    color: #fff;
  }
`;

const DisclosureContainer = css`
  text-align: center;
  max-width: 960px;
  margin: 3vh auto;

  a {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 12px;
  }
`;

const ModalButtonContainer = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  margin: 2vh auto;
  border-top: 1px solid #c9c9c9;
  padding-top: 1vh;

  span {
    color: ${DEFAULT_COLORS.link};
  }

  @media (max-width: 500px) {
    width: 90%;
  }
`;

const ModalButton = css`
  border: 0;
  background: inherit;
  color: ${DEFAULT_COLORS.link};
  cursor: pointer;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 801px) {
    color: #fff;
  }
`;

// Modal
const ModalBackground = css`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 20;
`;
const ModalContainer = css`
  margin: auto;
  background: white;
  width: ${props => (props.primary ? '50vw' : '70vw')};
  height: ${props => (props.primary ? '60vh' : '80vh')};
  padding: 3vh 3vw;
  border-radius: 10px;
  overflow: auto;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const ExitButton = css`
  top: ${props => (props.primary ? '19vh' : '9vh')};
  right: ${props => (props.primary ? '24vw' : '14vw')};
  position: absolute;
  border-radius: 50%;
  border: 1px solid #eee;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.5s ease;
  height: 33px;
  width: 33px;
  background: #fff;

  &:hover {
    background: #969696;
    color: #fff;
  }

  @media (max-width: 900px) {
    right: 3vw;
  }
`;

const ModalInformation = css`
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  line-height: 1.5em;

  br {
    line-height: 2em;
  }

  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;

const BoldText = css`
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  color: ${props => props.primary && '#C1794C'};
  text-align: ${props => props.primary && 'center'};
`;

const Text = css`
  color: #989898;
  font-size: 14px;
  font-weight: ${props => props.primary && '700'};
  margin-bottom: ${props => props.primary && '0'};
`;

const FooterText = css`
  margin-top: ${props => props.primary && '30px'};
  white-space: pre-line;
  text-align: center;
  font-size: ${DEFAULT_FONT.footerText};
  color: ${({ primary }) => primary && '#767676'};
`;

const ImageList = css`
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const HeadlineContainer = css`
  text-align: center;
  margin: 15px auto 20px auto;
  max-width: 650px;
  width: calc(100% - 20px);

  @media (max-width: 801px) {
    padding-top: 10px;
  }
`;

const HeadlineHeader = css`
  color: ${DEFAULT_COLORS.headline};
  font-size: ${DEFAULT_FONT.headline};
  font-weight: 900;
  margin-bottom: 10px;

  @media (max-width: 801px) {
    font-size: 22px;
    color: #000;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    color: ${DEFAULT_COLORS.headline};
    font-size: ${DEFAULT_FONT.headline};
  }
`;

const SubHeader = css`
  font-size: ${DEFAULT_FONT.text};
  font-weight: 200;
  display: none;
  color: ${DEFAULT_COLORS.headline};
  margin-bottom: 0;
`;

const SmallText = css`
  font-size: ${props => (props.secondary ? '0.9em' : '0.7em')};
  font-weight: ${props => (props.primary ? '700' : '200')};
  max-width: 500px;
  margin: auto;
`;

const ErrorText = css`
  color: #ee0000;
  text-align: center;
  font-size: 12px;
`;

const FinalDisclosureContainer = css`
  background-color: transparent;
  min-height: 20px;
  max-width: 800px;
  margin: 20px auto 0 auto;

  p,
  label {
    margin-bottom: 2px;
    font-size: ${DEFAULT_FONT.tcpa};
    color: #767676;
    line-height: 1.3;
  }
`;

// Loading Spinner
const Bounce = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
`;

const SpinnerContainer = css`
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
`;

const Spinner = css`
  width: 18px;
  height: 18px;
  background-color: #5598c1;
  border-radius: 100%;
  display: inline-block;
  animation: ${Bounce} 1.4s infinite ease-in-out both;
  animation-delay: ${props => props.delay};
`;

const LoadingContainer = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9;
`;

const SpinnerMainContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  padding: 40px;
  background: #fff;
`;

const CongratsHolder = css`
  /* Style property not used in this survey */
`;

const CheckboxWrapper = css`
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
`;

const CheckboxContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0;
`;

const CheckboxLabel = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > input {
    -webkit-appearance: none;
    height: 0;
    width: 0;
    margin-left: 5px;
  }

  > input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    left: 0;
    border: 1px solid #dedede;
    border-radius: 3px;
    background-color: white;
    box-shadow: inset #777 0 0 2px;
    margin: 4px;
  }
  > input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 6px;
    height: 12px;
    border: solid grey;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 9px;
    margin: 4px;
  }

  @media (max-width: 500px) {
    font-size: 0.8em;
  }
`;

const CheckboxParagraph = css`
  margin-left: 40px;
  margin-bottom: 0;
  font-size: 0.9em;
  text-align: left;
  font-style: italic;
`;

// Change Address container on final-frame step
const ChangeAddress = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  width: 100%;
  margin-top: 0;

  p {
    margin-bottom: 0;
  }
  button {
    color: ${DEFAULT_COLORS.link};
    text-decoration: underline;
    margin: 0;
    font-size: 13px;
  }
`;

const AddressSubtext = css`
  width: 100%;
  margin-bottom: 0;
`;

const LenderDropdown = css`
  background: #fff;
  margin-bottom: 10px;
  padding: 0 10px;
  position: absolute;
  width: 100%;

  p {
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    &:hover,
    &:focus {
      background: ${DEFAULT_COLORS.link};
      color: #fff;
      cursor: pointer;
    }
  }
`;

const FlexContainer = css`
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const StepOverviewContainer = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  border-top: solid 1px #bab9ba;
  border-bottom: solid 1px #bab9ba;
  color: #bab9ba;
  font-weight: 700;
  margin-bottom: 10px;
  .surveyContainerFF & {
    display: none;
  }
`;

const OverviewDetails = css`
  margin: 15px 0;
  color: ${props => (props.isHighlighted ? '#00486b' : '#bab9ba')};

  span {
    background: ${props => (props.isHighlighted ? '#00486b' : '#bab9ba')};
  }
`;

const OverviewNumber = css`
  background: #bab9ba;
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  margin-right: 3px;
  text-align: center;
  width: 22px;
`;

export default {
  HeaderContainer,
  SvgLine,
  HeaderImage,
  AppWrapper,
  SurveyBackground,
  SurveyContainer,
  QuestionNumber,
  FormContainer,
  GlobalSealImg,
  ChoiceContainer,
  InputContainer,
  inputStyle,
  InputField,
  StyledTextArea,
  AddressContainer,
  StyledSelect,
  StyledZip,
  FinalInputContainer,
  HiddenContainer,
  QuestionTitle,
  InputTitle,
  Subtitle,
  TipContainer,
  QuestionContainer,
  QuestionBodyContainer,
  StyledSlider,
  StyledThumb,
  DisplayNumber,
  NumberContainer,
  SliderContainer,
  FillContainer,
  ProgressContainer,
  StatusBar,
  FooterNumberStatus,
  SubmitButton,
  BackButton,
  ButtonContainer,
  FooterContainer,
  DisclosureContainer,
  ModalButtonContainer,
  ModalButton,
  ModalBackground,
  ModalContainer,
  ExitButton,
  ModalInformation,
  BoldText,
  Text,
  FooterText,
  ImageList,
  HeadlineContainer,
  HeadlineHeader,
  SubHeader,
  SmallText,
  ErrorText,
  FinalDisclosureContainer,
  SpinnerContainer,
  Spinner,
  LoadingContainer,
  SpinnerMainContainer,
  CongratsHolder,
  CheckboxWrapper,
  CheckboxContainer,
  CheckboxLabel,
  CheckboxParagraph,
  ChangeAddress,
  AddressSubtext,
  LenderDropdown,
  FlexContainer,
  StepOverviewContainer,
  OverviewDetails,
  OverviewNumber,
};
